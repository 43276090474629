import { PrefetchResources } from '../../lib/prefetch-resources';

export const PrefetchLinks = () => {
  return (
    <>
      {PrefetchResources.map((resource) => {
        if (resource.as === 'font') {
          return (
            <link
              key={resource.path}
              rel={resource.rel}
              href={resource.path}
              as="font"
              crossOrigin="anonymous"
            />
          );
        }
        return (
          <link key={resource.path} rel={resource.rel} href={resource.path} />
        );
      })}
    </>
  );
};
